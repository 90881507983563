import React, { useCallback, useEffect, useRef, useState } from 'react'
import c from './guides.module.scss'
import Sidebar from './sidebar/Sidebar'
import Article from './article/Article'
import { fs } from '../../../utils/firebase'
import Nav from './nav/Nav'
// import Vote from './vote/Vote'
import { useGuidesParams } from '../../../utils/hooks/useGuidesParams'
import Error from '../error/Error'

//Flatten content for botttom navigation
const getFlattenContent = (content) =>
  content.reduce(
    (accu, category) => [
      ...accu,
      ...category.articles.map((name, i) => ({
        category: category.category,
        name: name,
      })),
    ],
    []
  )

// Generate content structure with ordered categories and articles
const getContent = (contentStucture, articles) => {
  if (!contentStucture || !articles.length) return []
  const orderedCategoryList = contentStucture
    ? Object.entries(contentStucture).reduce((accu, category) => {
        accu[category[1].sort_index] = category[0]
        return accu
      }, [])
    : []

  const content = orderedCategoryList.map((categoryName, i) => ({
    category: categoryName,
    articles: articles
      .filter((article) => article.category === categoryName)
      .reduce((accu, article) => {
        accu[article.sort_index] = article.name
        return accu
      }, []),
  }))
  return content
}

const Guides = () => {
  const [activeArticle, setActiveArticle] = useState(null)
  const [articles, setArticles] = useState([])
  const [contentStucture, setContentStucture] = useState(null)
  const [showError, setshowError] = useState(false)
  const [loading, setLoading] = useState(true)
  const scrollRef = useRef()
  const guidePramas = useGuidesParams()
  const content = getContent(contentStucture, articles)
  const flattenedContent = getFlattenContent(content)

  const getContentStructure = useCallback(() => {
    if (!guidePramas.app || !guidePramas.category || !guidePramas.name) {
      return
    }
    fs.collection('content-structure')
      .doc(guidePramas.app)
      .get()
      .then((structure) => setContentStucture(structure.data()))
      .catch(() => {
        //TODO: update error handling method for different statuses
        setshowError(true)
      })
  }, [guidePramas])

  const getArticles = useCallback(() => {
    if (!guidePramas.app || !guidePramas.category || !guidePramas.name) {
      return
    }
    fs.collection('articles')
      .get()
      .then((snapshot) =>
        snapshot.docs.map((doc) => {
          return { ...doc.data(), id: doc.id }
        })
      )
      .then((articles) => setArticles(articles))
      .catch(() => {
        //TODO: update error handling method for different statuses
        //TODO: create page not found
        setshowError(true)
      })
  }, [guidePramas])

  const getArticle = useCallback(() => {
    if (!guidePramas.app || !guidePramas.category || !guidePramas.name) {
      return
    }

    //TODO: Save scroll position
    fs.collection('articles')
      .where('app', '==', guidePramas.app)
      .where('category', '==', guidePramas.category)
      .where('name', '==', guidePramas.name)
      .get()
      .then((snapshot) =>
        snapshot.docs.map((doc) => {
          return { ...doc.data(), id: doc.id }
        })
      )
      .then((article) => {
        setActiveArticle(article?.[0])
      })
      .then(() => {
        scrollRef.current.scrollTop = 0
      })
      .catch(() => {
        //TODO: update error handling method for different statuses
        //TODO: create page not found
        setshowError(true)
      })
  }, [guidePramas])

  useEffect(getArticles, [getArticles])
  useEffect(getContentStructure, [getContentStructure])
  useEffect(getArticle, [getArticle])
  return !showError ? (
    <div className={c.guides}>
      <div ref={scrollRef} className={c.display}>
        <Article onLoad={setLoading} onError={setshowError} />
        {!loading ? (
          <>
            {/* <Vote activeArticle={activeArticle} /> */}
            <Nav articleList={flattenedContent} activeArticle={activeArticle} />
          </>
        ) : null}
      </div>
      <div className={c.sidebar}>
        <Sidebar content={content} />
      </div>
    </div>
  ) : (
    <Error />
  )
}

export default Guides
