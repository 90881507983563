import React from 'react'
import ReactDOM from 'react-dom'
import './index.scss'
import App from './App.jsx'
import reportWebVitals from './reportWebVitals'
import { auth } from './utils/firebase'
import { AuthProvider } from '@monash/portal-auth'
import config from './utils/config'
import LoadingScreen from './components/utilities/loading-indicator/LoadingScreen'

ReactDOM.render(
  <React.StrictMode>
    <AuthProvider
      fbAuth={auth}
      oktaLoginUrl={config.oktaLoginUrl}
      callbackPath={'/authorization_code/callback'}
      tokenApi={config.tokenApi}
      nolanding={true}
      loadingPage={<LoadingScreen loaded={false} message='Logging in' />}>
      <App />
    </AuthProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
