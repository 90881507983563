import React from 'react'

const Icon = ({ children }) => {
  // render
  return <>{children}</>
}

export default Icon

// icons - - -

// ChevronUp
Icon.ChevronUp = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    class='icon icon-tabler icon-tabler-chevron-up'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    strokeWidth='1.5'
    stroke='currentColor'
    fill='none'
    strokeLinecap='round'
    strokeLinejoin='round'>
    <path stroke='none' d='M0 0h24v24H0z' fill='none' />
    <polyline points='6 15 12 9 18 15' />
  </svg>
)

//ArrowLeft
Icon.ArrowLeft = () => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    strokeWidth='1.5'
    stroke='currentColor'
    fill='none'
    strokeLinecap='round'
    strokeLinejoin='round'>
    <path stroke='none' d='M0 0h24v24H0z' fill='none' />
    <line x1='5' y1='12' x2='19' y2='12' />
    <line x1='5' y1='12' x2='11' y2='18' />
    <line x1='5' y1='12' x2='11' y2='6' />
  </svg>
)

//ArrowRight
Icon.ArrowRight = () => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    strokeWidth='1.5'
    stroke='currentColor'
    fill='none'
    strokeLinecap='round'
    strokeLinejoin='round'>
    <path stroke='none' d='M0 0h24v24H0z' fill='none' />
    <line x1='5' y1='12' x2='19' y2='12' />
    <line x1='13' y1='18' x2='19' y2='12' />
    <line x1='13' y1='6' x2='19' y2='12' />
  </svg>
)

//ThumbUp

Icon.ThumbUp = () => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    strokeWidth='1.5'
    stroke='#333333'
    fill='none'
    strokeLinecap='round'
    strokeLinejoin='round'>
    <path stroke='none' d='M0 0h24v24H0z' fill='none' />
    <path d='M7 11v8a1 1 0 0 1 -1 1h-2a1 1 0 0 1 -1 -1v-7a1 1 0 0 1 1 -1h3a4 4 0 0 0 4 -4v-1a2 2 0 0 1 4 0v5h3a2 2 0 0 1 2 2l-1 5a2 3 0 0 1 -2 2h-7a3 3 0 0 1 -3 -3' />
  </svg>
)

//ThumbDown

Icon.ThumbDown = () => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    strokeWidth='1.5'
    stroke='#333333'
    fill='none'
    strokeLinecap='round'
    strokeLinejoin='round'>
    <path stroke='none' d='M0 0h24v24H0z' fill='none' />
    <path d='M7 13v-8a1 1 0 0 0 -1 -1h-2a1 1 0 0 0 -1 1v7a1 1 0 0 0 1 1h3a4 4 0 0 1 4 4v1a2 2 0 0 0 4 0v-5h3a2 2 0 0 0 2 -2l-1 -5a2 3 0 0 0 -2 -2h-7a3 3 0 0 0 -3 3' />
  </svg>
)

// ChevronUp
Icon.ChevronUp = () => (
  <svg width='24' height='24' viewBox='0 0 24 24'>
    <path d='M12 8l-6 6 1.41 1.41L12 10.83l4.59 4.58L18 14z' />
  </svg>
)

// ChevronDown
Icon.ChevronDown = () => (
  <svg width='24' height='24' viewBox='0 0 24 24'>
    <path d='M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z' />
  </svg>
)

//Plus

Icon.Plus = () => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    strokeWidth='1.5'
    stroke='#333333'
    fill='none'
    strokeLinecap='round'
    strokeLinejoin='round'>
    <path stroke='none' d='M0 0h24v24H0z' fill='none' />
    <line x1='12' y1='5' x2='12' y2='19' />
    <line x1='5' y1='12' x2='19' y2='12' />
  </svg>
)

// Minus
Icon.Minus = () => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    strokeWidth='1.5'
    stroke='#333333'
    fill='none'
    strokeLinecap='round'
    strokeLinejoin='round'>
    <path stroke='none' d='M0 0h24v24H0z' fill='none' />
    <line x1='5' y1='12' x2='19' y2='12' />
  </svg>
)

// Logout
Icon.Logout = () => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    strokeWidth='1.5'
    stroke='currentColor'
    fill='none'
    strokeLinecap='round'
    strokeLinejoin='round'>
    <path d='M0 0h24v24H0z' stroke='none' />
    <path d='M14 8V6a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h7a2 2 0 0 0 2-2v-2' />
    <path d='M7 12h14l-3-3m0 6l3-3' />
  </svg>
)

// External
Icon.External = () => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    strokeWidth='1.5'
    stroke='currentColor'
    fill='none'
    strokeLinecap='round'
    strokeLinejoin='round'>
    <path d='M0 0h24v24H0z' stroke='none' />
    <path d='M11 7H6a2 2 0 0 0-2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2-2v-5m-7 1L20 4m-5 0h5v5' />
  </svg>
)

//IconBell
Icon.Bell = () => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    strokeWidth='1.5'
    stroke='currentColor'
    fill='none'
    strokeLinecap='round'
    strokeLinejoin='round'>
    <path stroke='none' d='M0 0h24v24H0z' fill='none' />
    <path d='M10 5a2 2 0 0 1 4 0a7 7 0 0 1 4 6v3a4 4 0 0 0 2 3h-16a4 4 0 0 0 2 -3v-3a7 7 0 0 1 4 -6' />
    <path d='M9 17v1a3 3 0 0 0 6 0v-1' />
  </svg>
)
