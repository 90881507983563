import React from 'react'
import Banner from './banner/Banner'
import c from './home.module.scss'

//TODO: refactor when there are banners for multiple apps
const app = 'course-mapper'

const Home = () => {
  return (
    <div className={c.Home}>
      <Banner app={app} />
    </div>
  )
}

export default Home
