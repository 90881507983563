import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/functions'
import 'firebase/firestore'
import 'firebase/storage'
import { proxy, functions_region } from '@monash/portal-auth'
import { firebaseConfig, proxyConfig } from './config'

const fb = firebase.initializeApp(firebaseConfig)
const fbProxy = proxy(fb, false)
const fs = proxyConfig
  ? fbProxy.firestore(proxyConfig['firestoreHost'])
  : fb.firestore()
const auth = proxyConfig ? fbProxy.auth(proxyConfig['authHost']) : fb.auth()
const fn = proxyConfig
  ? fbProxy.functions(proxyConfig['functionsHost'])
  : fb.functions(functions_region)
const storage = fb.storage() 
storage._delegate._host = proxyConfig['storageHost'] // TODO: move to auth liberary
const arrayUnion = firebase.firestore.FieldValue.arrayUnion
const arrayRemove = firebase.firestore.FieldValue.arrayRemove

// if (window.location.hostname === 'localhost') {
//   fs.useEmulator('localhost', 5001)
//   fs.useEmulator('localhost', 8080)
// }

export default fb
export { fs, auth, fn, storage, arrayUnion, arrayRemove }
