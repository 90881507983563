import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { transformTextforDisplay } from '../../../../../utils/fns/transformTextforDisplay'
import { useGuidesParams } from '../../../../../utils/hooks/useGuidesParams'
import { Link } from '../../../../../utils/Router'
import Icon from '../../../../utilities/icons/Icon'
import c from './collapsible.module.scss'

const Collapsible = ({ header, items }) => {
  const [height, setHeight] = useState(0)
  const [isCollapsed, setIsCollapsed] = useState(true)
  const guidePramas = useGuidesParams()

  const observer = useMemo(
    () =>
      new ResizeObserver((entries) =>
        setHeight(entries[0].target.scrollHeight)
      ),
    []
  )

  const body = useCallback(
    (node) => {
      if (node) {
        observer.observe(node)
      }
    },
    [observer]
  )

  useEffect(() => {
    return () => observer.disconnect()
  }, [observer])

  useEffect(() => {
    if (guidePramas.category === header) {
      setIsCollapsed(false)
    } else {
      setIsCollapsed(true)
    }
  }, [guidePramas.category, header])

  return (
    <div className={c.collapsible}>
      <span className={c.header} onClick={() => setIsCollapsed(!isCollapsed)}>
        <h2>{transformTextforDisplay(header)}</h2>
        <span
          className={c.toggle}
          style={{ transform: `rotate(${isCollapsed ? 0 : 540}deg)` }}>
          <Icon.ChevronDown />
        </span>
      </span>
      <div></div>
      <ul
        className={c.list}
        ref={body}
        style={{
          height: `${isCollapsed ? 0 : height}px`,
          opacity: `${isCollapsed ? 0 : 1}`,
        }}>
        {items.map((item, i) => {
          return (
            <li
              className={
                guidePramas.name === item && guidePramas.category === header
                  ? c.active
                  : ''
              }
              key={i}>
              <Link to={`/${guidePramas.app}/guides/${header}/${item}`}>{` ${
                i + 1
              }. ${transformTextforDisplay(item)}`}</Link>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default Collapsible
