import React from 'react'
import c from './main.module.scss'
import { Route } from '../../utils/Router'
import Guides from '../pages/guides/Guides'
import Topnav from '../topnav/Topnav'
import Home from '../pages/home/Home'
import FAQs from '../pages/FAQs/FAQs'

const Main = () => {
  return (
    <div className={c.main}>
      <Topnav />
      <Route to='/' exact>
        <Home />
      </Route>
      <Route to='guides'>
        <Guides />
      </Route>
      <Route to='faqs'>
        <FAQs />
      </Route>
    </div>
  )
}

export default Main
