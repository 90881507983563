const config = {
  debug: {
    firebase: {
      apiKey: 'AIzaSyATV2oZaBVTyndSgq3S7sawNg9Hfq2FnRE',
      authDomain: 'monash-central-dogma-dev.firebaseapp.com',
      projectId: 'monash-central-dogma-dev',
      storageBucket: 'monash-central-dogma-dev.appspot.com',
      messagingSenderId: '130585339607',
      appId: '1:130585339607:web:6c83210d4b2229eb8bb292',
    },
    proxy: {
      authHost: 'localhost:9099',
      firestoreHost: 'localhost:8080',
      functionsHost: 'localhost:5001',
      storageHost: 'localhost:9199',
      cdnHost: 'user-guide-dev.m.monash',
    },
    oktaLoginUrl: 'http://localhost:9080/sso/authorization_code/login',
    serviceBaseUrl: 'http://localhost:9080',
    tokenApi:
      'http://localhost:9080/sso/authorization_code/firebase_custom_token',
  },
  local: {
    firebase: {
      apiKey: 'AIzaSyATV2oZaBVTyndSgq3S7sawNg9Hfq2FnRE',
      authDomain: 'monash-central-dogma-dev.firebaseapp.com',
      projectId: 'monash-central-dogma-dev',
      storageBucket: 'monash-central-dogma-dev.appspot.com',
      messagingSenderId: '130585339607',
      appId: '1:130585339607:web:6c83210d4b2229eb8bb292',
    },
    proxy: {
      authHost: 'user-guide-dev.m.monash',
      firestoreHost: 'user-guide-dev.m.monash',
      functionsHost: 'user-guide-dev.m.monash',
      storageHost: 'user-guide-dev.m.monash',
      cdnHost: 'user-guide-dev.m.monash',
    },
    oktaLoginUrl:
      'https://user-guide-dev.m.monash/sso/authorization_code/login',
    serviceBaseUrl: 'https://user-guide-dev.m.monash',
    tokenApi:
      'https://user-guide-dev.m.monash/sso/authorization_code/firebase_custom_token',
  },
  dev: {
    firebase: {
      apiKey: 'AIzaSyATV2oZaBVTyndSgq3S7sawNg9Hfq2FnRE',
      authDomain: 'monash-central-dogma-dev.firebaseapp.com',
      projectId: 'monash-central-dogma-dev',
      storageBucket: 'monash-central-dogma-dev.appspot.com',
      messagingSenderId: '130585339607',
      appId: '1:130585339607:web:6c83210d4b2229eb8bb292',
    },
    proxy: {
      authHost: `${window.location.host}`,
      firestoreHost: `${window.location.host}`,
      functionsHost: `${window.location.host}`,
      storageHost: `${window.location.host}`,
      cdnHost: `${window.location.host}`,
    },
    oktaLoginUrl: `${window.location.origin}/sso/authorization_code/login`,
    serviceBaseUrl: `${window.location.origin}`,
    tokenApi: `${window.location.origin}/sso/authorization_code/firebase_custom_token`,
  },
  uat: {
    firebase: {
      apiKey: 'AIzaSyASXgJLpFMI38G96muzzbSlF57bnfOuL6M',
      authDomain: 'monash-central-dogma-uat.firebaseapp.com',
      projectId: 'monash-central-dogma-uat',
      storageBucket: 'monash-central-dogma-uat.appspot.com',
      messagingSenderId: '1087737768695',
      appId: '1:1087737768695:web:bfa9f2b29b5bab777601f2',
    },
    proxy: {
      authHost: `${window.location.host}`,
      firestoreHost: `${window.location.host}`,
      functionsHost: `${window.location.host}`,
      storageHost: `${window.location.host}`,
      cdnHost: `${window.location.host}`,
    },
    oktaLoginUrl: `${window.location.origin}/sso/authorization_code/login`,
    serviceBaseUrl: `${window.location.origin}`,
    tokenApi: `${window.location.origin}/sso/authorization_code/firebase_custom_token`,
  },
  prod: {
    firebase: {
      apiKey: 'AIzaSyAqFNMyTSQiK3sbumzAwPyycd8_G-hDnGU',
      authDomain: 'monash-central-dogma-prod.firebaseapp.com',
      projectId: 'monash-central-dogma-prod',
      storageBucket: 'monash-central-dogma-prod.appspot.com',
      messagingSenderId: '360388036181',
      appId: '1:360388036181:web:faed826248b672557076dc',
    },
    proxy: {
      authHost: `${window.location.host}`,
      firestoreHost: `${window.location.host}`,
      functionsHost: `${window.location.host}`,
      storageHost: `${window.location.host}`,
      cdnHost: `${window.location.host}`,
    },
    oktaLoginUrl: `${window.location.origin}/sso/authorization_code/login`,
    serviceBaseUrl: `${window.location.origin}`,
    tokenApi: `${window.location.origin}/sso/authorization_code/firebase_custom_token`,
  },
}

const react_app_env = process.env.REACT_APP_ENV

const getEnvConfig = () => {
  let env = (react_app_env || 'local').trim().toLowerCase()

  let host = window.location.host.split('.')[0]
  if (host.includes('-dev')) env = 'dev'
  if (host.includes('-uat') || host === 'user-guide-qa') env = 'uat'
  if (host.includes('-prod') || host === 'user-guide') env = 'prod'

  return config[env]
}

const envConfig = getEnvConfig()
const firebaseConfig = envConfig.firebase
const proxyConfig = envConfig.proxy

export default envConfig
export { firebaseConfig, proxyConfig }
