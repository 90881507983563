import React from 'react'
import c from './error.module.scss'

const Error = () => {
  return (
    <div className={c.error}>
      <div className={c.content}>
        <h1> Oops! Something went wrong :(</h1>
        <p>Sorry, something went wrong there. Please Try again.</p>
        <button
          onClick={() => {
            window.location.reload()
          }}>
          Try again
        </button>
      </div>
    </div>
  )
}

export default Error
