import React, { useCallback, useEffect, useState } from 'react'
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import c from './article.module.scss'
import { useGuidesParams } from '../../../../utils/hooks/useGuidesParams'
import getPublicImageURL from '../../../../utils/fns/getPublicImageURL'
import getPublicArticleURL from '../../../../utils/fns/getPublicArticleURL'

const Article = ({ onLoad, onError }) => {
  const [displayContent, setDisplayContent] = useState(null)
  const article = useGuidesParams()

  const replaceFileNamesbyURLs = useCallback(
    async (content) => {
      let rx = new RegExp(/(?<=!\[.*\]\().*(?=\))/gi)
      const fileNames = [...new Set(content.match(rx))]
      const promises = fileNames.map((item) => getPublicImageURL(article, item))

      if (promises) {
        await Promise.allSettled(promises).then((results) =>
          results.forEach((item, i) => {
            // Prevent double replacement issue
            content = content.replaceAll(
              '(' + fileNames[i] + ')',
              '(' + item.value + ')'
            )
          })
        )
      }

      return content
    },
    [article]
  )

  const getArticleFile = useCallback(() => {
    if (!article.app || !article.category || !article.name) return
    onLoad(true)
    //TODO: add relative path to article documents

    fetch(getPublicArticleURL(article))
      .then((res) => res.text())
      .then((text) => replaceFileNamesbyURLs(text))
      .then((raw) => {
        setDisplayContent(raw)
      })
      .then(() => {
        onLoad(false)
      })
      .catch((err) => {
        //TODO: update error handling method for different statuses
        onError(true)
      })
  }, [article, onLoad, onError, replaceFileNamesbyURLs])

  useEffect(() => {
    getArticleFile()
  }, [getArticleFile])

  return (
    <div className={c.article}>
      <ReactMarkdown rehypePlugins={[rehypeRaw]}>
        {displayContent}
      </ReactMarkdown>
    </div>
  )
}

export default Article
