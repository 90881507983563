import React, { useState } from 'react'
import c from './FAQs.module.scss'
import content from '../../../assets/faqs-content/course-mapper/questions.json'
import QuestionCard from './questionCard/QuestionCard'
import Tabs from './tabs/Tabs'

const categorySet = [
  ...[...new Set(content.map((item) => item.category))]
    .sort()
    .filter((item) => item !== 'Other'),
]

const tabs = ['All', ...categorySet, 'Other']

const FAQs = () => {
  const [activeTab, setActiveTab] = useState('All')
  const onTabChange = (tab) => {
    setActiveTab(tab)
  }

  const questions =
    activeTab === 'All'
      ? content
      : [...content.filter((item) => item.category === activeTab)]

  return (
    <div className={c.FAQs}>
      <div className={c.content}>
        <h1>Frenquently asked questions</h1>
        <Tabs tabs={tabs} activeTab={activeTab} onChange={onTabChange} />
        <ul className={c.list}>
          {questions.map((item) => (
            <li key={item.question}>
              <QuestionCard question={item.question} answer={item.answer} />
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default FAQs
