import React, { useEffect, useState, useContext } from 'react'

export const RouterState = React.createContext()

// regex for removing the first /
const rx = /^\//
const cleanPath = (path) => (path ? `/${path.replace(rx, '')}` : path)

//
// <Router />

const Router = ({ children }) => {
  const [route, setRoute] = useState({
    set: (data) => {
      return setRoute((f) => {
        return { ...f, ...data }
      })
    },
    path: cleanPath(window.location.pathname),
  })

  const redirect = (to, data) => {
    to = cleanPath(to)
    if (cleanPath(window.location.pathname) !== to) {
      window.history.pushState({ page: to }, '', to)
      route.set({ path: to, data })
    }
  }

  useEffect(() => {
    if (!window.history.state)
      window.history.pushState(
        { page: window.location.pathname },
        document.title
      )
  }, [])

  useEffect(() => {
    const urlChange = (e) => {
      setRoute((f) => {
        return { ...f, path: e.state.page, data: null }
      })
    }

    window.addEventListener('popstate', urlChange)

    return () => window.removeEventListener('popstate', urlChange)
  }, [])

  return (
    <RouterState.Provider value={{ route, redirect }}>
      {children}
    </RouterState.Provider>
  )
}

//
// <Route />

const Route = ({ children, to, not, transition, exact }) => {
  to = cleanPath(to)
  const { route } = useContext(RouterState)
  const [routed, setRouted] = useState()
  const [mounted, setMounted] = useState(false)
  const [propMount, setPropMount] = useState(false)

  // transition

  const unmountDelay = transition?.unmountDelay || 300
  const mountDelay = transition?.mountDelay || 300

  useEffect(() => {
    let t

    if (!routed) {
      setPropMount(false)
      t = setTimeout(() => setMounted(false), unmountDelay)
    } else {
      setTimeout(() => {
        setMounted(true)
        t = setTimeout(() => {
          setPropMount(true)
        }, 10)
      }, mountDelay)
    }

    return () => clearTimeout(t)
  }, [mountDelay, mounted, routed, unmountDelay])

  useEffect(() => {
    const rx = new RegExp(`^${cleanPath(not)}`, 'gi')
    if (!rx.test(route.path))
      setRouted(exact ? route.path === to : route.path.includes(to))
  }, [exact, not, route.path, to])

  // render

  return (
    mounted &&
    React.Children.map(children, (child) =>
      React.cloneElement(child, {
        mounted: propMount ? 'true' : null,
        data: route.data,
      })
    )
  )
}

//
// <Link />

const Link = ({ children, to, className, data, callback }) => {
  to = cleanPath(to)
  const { route } = useContext(RouterState)
  const [active, setActive] = useState(
    cleanPath(window.location.pathname).includes(to)
  )

  const handleClick = (e) => {
    e.preventDefault()

    if (callback) {
      callback(e)
    }

    if (cleanPath(window.location.pathname) !== to) {
      window.history.pushState({ page: to }, '', to)
      route.set({ path: to, data })
    }
  }

  // check if route is active

  useEffect(() => {
    setActive(route.path === to)
  }, [to, route.path])

  // render

  return (
    <a
      href={to}
      onClick={handleClick}
      className={className}
      active={active ? '' : null}>
      {children}
    </a>
  )
}

export { Router, Route, Link }
