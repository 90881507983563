import React from 'react'
import c from './tabs.module.scss'

const Tabs = ({ onChange, tabs, activeTab }) => {
  return (
    <div className={c.tabs}>
      <ul className={c.list}>
        {tabs.map((item, i) => {
          return (
            <li
              className={item === activeTab ? c.active : ''}
              key={i}
              onClick={() => onChange(item)}>
              {item}
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default Tabs
