import { useContext, useMemo } from 'react'
import { RouterState } from '../Router'

export const useGuidesParams = () => {
  const { route } = useContext(RouterState)
  const app = route.path.split('/')[1]
  const category = route.path.split('/')[3]
  const name = route.path.split('/')[4]

  return useMemo(
    () => ({
      app,
      category,
      name,
    }),
    [app, category, name]
  )
}
