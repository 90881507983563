import React, { useState, useContext } from 'react'
import c from './profile-menu.module.scss'
import { UserData } from '../../../utils/wrappers/user-data-wrapper/UserDataWrapper'
import { AuthContext } from '@monash/portal-auth'
import Icon from '../../utilities/icons/Icon'
import Transition from '../../utilities/transition/Transition'

const ProfileMenu = () => {
  const { user } = useContext(UserData)
  const authCtx = useContext(AuthContext)
  const [showProfileMenu, setShowProfileMenu] = useState(false)

  const logout = () => {
    authCtx.logout('https://monash.edu')
    console.log('bye')
  }

  const items = [
    {
      name: 'Log out',
      icon: <Icon.Logout />,
      fn: () => logout(),
    },
  ]

  const initials = () => {
    if (user) {
      let names = user.displayName?.split(' ')
      return (
        names[0][0].toUpperCase() + names[names.length - 1][0].toUpperCase()
      )
    } else {
      return ''
    }
  }

  // render

  return (
    <div className={c.proileMenu}>
      <button
        className={c.profileMenuButton}
        onClick={(e) => setShowProfileMenu(!showProfileMenu)}>
        {initials()}
      </button>

      <Transition
        show={showProfileMenu}
        enterClass={c.enter}
        exitClass={c.exit}>
        <menu className={c.menu}>
          <div className={c.menuHeader}>
            <div className={c.initials}>{initials()}</div>
            <div className={c.meta}>
              <div className={c.name}>{user?.displayName}</div>
              <div className={c.email}>{user?.email}</div>
            </div>
          </div>
          {items.map((item, i) => (
            <div className={c.item} key={i} onClick={item.fn}>
              {item.icon} {item.name}
            </div>
          ))}
        </menu>
      </Transition>
    </div>
  )
}

export default ProfileMenu
