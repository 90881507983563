import React from 'react'
import c from './banner.module.scss'
import Lottie from 'react-lottie'
import appLogo from '../../../../assets/lotties/course-mapper.json'
import { Link } from '../../../../utils/Router'
import Error from '../../error/Error'
import { useLatestReleaseNotesLink } from '../../../../utils/hooks/useLatestReleaseNotesLink'
import Icon from '../../../utilities/icons/Icon'

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: appLogo,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
}

const Banner = ({ app }) => {
  const { showError, latestReleaseNotesLink } = useLatestReleaseNotesLink(app)

  //TODO: getFirstArticle custom hook

  return !showError ? (
    <div className={c.Banner}>
      <h1>Course Mapper User Guide</h1>
      <p>A single, digital and integrated course mapping platform</p>
      <div className={c.whatsNew}>
        <Link to={latestReleaseNotesLink}>
          <Icon.Bell />
          What's New
        </Link>
      </div>
      <div className={c.links}>
        <Link to={`${app}/guides/introduction/what-is-course-mapper`}>
          Guides & Tutorials
        </Link>
        <Link to={`faqs`}>FAQs</Link>
      </div>
      <div className={c.appLogo}>
        <Lottie options={defaultOptions} height={300} width={400} />
      </div>
    </div>
  ) : (
    <Error />
  )
}

export default Banner
