import React from 'react'
import Main from './components/main/Main'
import './app.scss'
import { Router } from './utils/Router'
import { AuthContext } from '@monash/portal-auth'
import { useContext } from 'react'
import UserDataWrapper from './utils/wrappers/user-data-wrapper/UserDataWrapper'

function App() {
  const authCtx = useContext(AuthContext)

  if (authCtx.error) {
    return <div>Error</div>
  }

  return (
    <Router>
      <UserDataWrapper>
        <Main />
      </UserDataWrapper>
    </Router>
  )
}

export default App
