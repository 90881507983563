import React from 'react'
import Collapsible from './collapsible/Collapsible'
import c from './sidebar.module.scss'

const Sidebar = ({ content }) => {
  return (
    <div className={c.sidebar}>
      <ul>
        {content?.map((item, i) => {
          return (
            <li key={i}>
              <Collapsible header={item.category} items={item.articles} />
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default Sidebar
