import React, { useCallback, useEffect, useMemo, useState } from 'react'
import Icon from '../../../utilities/icons/Icon'
import c from './question-card.module.scss'

const QuestionCard = ({ question, answer }) => {
  const [height, setHeight] = useState(0)
  const [isCollapsed, setIsCollapsed] = useState(true)

  const observer = useMemo(
    () =>
      new ResizeObserver((entries) =>
        setHeight(entries[0].target.scrollHeight)
      ),
    []
  )

  const body = useCallback(
    (node) => {
      if (node) {
        observer.observe(node)
      }
    },
    [observer]
  )

  useEffect(() => {
    return () => observer.disconnect()
  }, [observer])

  return (
    <div
      className={c.questionCard}
      onClick={() => setIsCollapsed(!isCollapsed)}>
      <div className={c.header}>
        {question}
        {isCollapsed ? <Icon.Plus /> : <Icon.Minus />}
      </div>
      <p
        className={c.answer}
        ref={body}
        style={{
          height: `${isCollapsed ? 0 : height}px`,
          opacity: `${isCollapsed ? 0 : 1}`,
          marginTop: `${isCollapsed ? 0 : 20}px`,
        }}>
        {answer}
      </p>
    </div>
  )
}

export default QuestionCard
