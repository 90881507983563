import React from 'react'
import { transformTextforDisplay } from '../../../../utils/fns/transformTextforDisplay'
import { Link } from '../../../../utils/Router'
import Icon from '../../../utilities/icons/Icon'
import c from './nav.module.scss'

const Nav = ({ articleList, activeArticle }) => {
  //TODO: Display when active article is out, instead of using guide params. Add transitions to smoothen text changes.
  // const guideParams = useGuidesParams()
  if (!articleList || !activeArticle) {
    return null
  }
  const activeArticleIndex = articleList.findIndex(
    (article) =>
      article.category === activeArticle.category &&
      article.name === activeArticle.name
  )

  const previousArticle = articleList[activeArticleIndex - 1]
  const nextArticle = articleList[activeArticleIndex + 1]

  return (
    <div className={c.nav}>
      {activeArticleIndex > 0 ? (
        <span className={c.link}>
          <Link
            to={`course-mapper/guides/${previousArticle.category}/${previousArticle.name}`}>
            <Icon.ArrowLeft />
            {transformTextforDisplay(previousArticle.name)}
          </Link>
        </span>
      ) : null}
      {activeArticleIndex < articleList.length - 1 ? (
        <span
          className={`${activeArticleIndex === 0 ? c.firstLink : ''} ${
            c.link
          }`}>
          <Link
            to={`course-mapper/guides/${nextArticle.category}/${nextArticle.name}`}>
            {transformTextforDisplay(nextArticle.name)}
            <Icon.ArrowRight />
          </Link>
        </span>
      ) : null}
    </div>
  )
}

export default Nav
