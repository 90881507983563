import { useEffect, useMemo, useState, useCallback } from 'react'
import { fs } from '../firebase'

export const useLatestReleaseNotesLink = (app) => {
  const [showError, setshowError] = useState(false)
  const [latestReleaseNotesLink, setLatestReleaseNotesLink] = useState(null)

  const getLatestReleaseNotesLink = useCallback(() => {
    fs.collection('articles')
      .where('app', '==', app)
      .where('category', '==', '2022-Release-notes')
      .where('sort_index', '==', 0)
      .get()
      .then((snapshot) =>
        snapshot.docs.map((doc) => {
          return setLatestReleaseNotesLink(
            `${app}/guides/2022-Release-notes/${doc.data().name}`
          )
        })
      )
      .catch(() => {
        //TODO: update error handling method for different statuses
        setshowError(true)
      })
    return
  }, [app])

  useEffect(getLatestReleaseNotesLink, [getLatestReleaseNotesLink])
  return useMemo(
    () => ({
      showError,
      latestReleaseNotesLink,
    }),
    [showError, latestReleaseNotesLink]
  )
}
